import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

export default ({ prev, next, width }) => {
  return (
    <div className="footer">
      {
        width < 600 ?
        <React.Fragment>
        {prev !== null && <div style={{textAlign: 'left'}}><Link to={prev.url}>&#8678; {prev.title}</Link></div>}
        <div style={{textAlign: 'center'}}><Link to="/"><i className="home icon"></i></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:learnwithexamples@outlook.com" className="email">Learn with Examples © 2020</a></div>
        {next !== null && <div style={{textAlign: 'right'}}><Link to={next.url}>{next.title} &#8680;</Link></div>}
        </React.Fragment>
        :
        <React.Fragment>
        {prev !== null && <span className="left"><Link to={prev.url}>&#8678; {prev.title}</Link></span>}
        <span className="middle"><Link to="/"><i className="home icon"></i></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:learnwithexamples@outlook.com" className="email">Learn with Examples © 2020</a></span>
        {next !== null && <span className="right"><Link to={next.url}>{next.title} &#8680;</Link></span>}
        </React.Fragment>
      }
    </div>
  );
};
