import React from 'react';
import './Header.css';
import Alert from './Alert';

export default ({ title, width, showSidebar, setShowSidebar }) => {
  return (
    <React.Fragment>
  <div className="navbar container-fluid">
    {width > 768 && <i className={`angle double ${showSidebar ? 'left' : 'right'} icon navbar-btn`} onClick={() => setShowSidebar(!showSidebar)}></i>}
    <span className="header">{title}</span>
    <span className="followmeTxt" style={{fontSize: '1rem'}}>FollowMe:</span>
    <span className="followme"><a href="https://twitter.com/LearnWithEx" target="_blank" rel="noopener noreferrer"><i className="twitter square icon"></i></a></span>
    <span className="followme"><a href="https://www.facebook.com/learnwithexamples" target="_blank" rel="noopener noreferrer"><i className="facebook square icon"></i></a></span>
    <span className="followme"><a href="https://www.youtube.com/channel/UC6voIK-YuToVpEw6SnGTpTg" target="_blank" rel="noopener noreferrer"><i className="youtube square icon"></i></a></span>
  </div>
  <Alert />
  </React.Fragment>
  )}
