import React, { useState } from 'react';
import './Desp.css';

export default ({ desp=null, html=null, url=null }) => {
  const [size, setSize] = useState(100);
  const [visible, setVisible] = useState(true);
  return (
    <React.Fragment>
      <i className={`eye ${visible ? 'slash' : ''} icon lefticons`} onClick={() => setVisible(!visible)}></i>
      {visible &&
        <React.Fragment>
          <i className="plus square outline icon lefticons" onClick={() => setSize(size + 10)}></i>
          <i className="minus square outline icon lefticons" onClick={() => setSize(Math.max(0, size - 10))}></i>
          {desp && <span className="desp"><pre style={{fontSize: size + '%', padding: '5px'}}>{desp}</pre></span>}
          {html && <span className="html"><div dangerouslySetInnerHTML={{__html: html}} style={{fontSize: size + '%'}}></div></span>}
          {url && <div className="center" style={{maxWidth: '1200px'}}><iframe width="80%" height="400" title="video record" src={url}></iframe></div>}
      </React.Fragment>}
    </React.Fragment>
  );
};