import React from 'react';
import './Scrollbar.css';
// https://www.cssportal.com/style-input-range/

export default ({ position, setPosition }) => {
  return (
    <React.Fragment>
      <input type="range" min="1" max="100" value={position} onChange={event => setPosition(Math.max(25,Math.min(70,event.target.value)))} />
  </React.Fragment>
);
}