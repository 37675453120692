import React, { useState } from 'react';
import './Alert.css';

export default () => {
  const [show, setShow] = useState(true);
  return (
    show &&
    <div className="alert">
      <span className="closebtn" onClick={() => setShow(false)}>&times;</span>
      {/* <div>A top 5 worldwide smartphone manufacturer is hiring talents to develop 4G/5G modem chipset. Multiple openings in California, USA. <a href="mailto:learnwithexamples@outlook.com" className="email contactme">Contact Me</a> if you are interested.</div> */}
      <div>Oct/01/2022: Apple Inc (California, US) is looking for talents with 3GPP RAN2 experience and a good physical layer understanding. <a href="mailto:learnwithexamples@outlook.com" className="email contactme">Contact Me</a> if you are interested.</div>
    </div>
  );
}
