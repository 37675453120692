const example0 = [
  {
    "type": "main",
    "channels": [
      { "interface": "sc_signal", "type": "bool", "name": "cmd", "to": ["b.cmd"], "init": "true" },
      { "interface": "sc_buffer", "type": "bool", "name": "res", "from": "b.res" } ],
    "fields": [ { "type": "Bike", "name": "b", "isClass": "true"}, {"type": "Dummy*", "name": "d", "isClass": "true" } ]
  },
  { "type": "Product",
    "includes": [ "string" ],
    "fields": [ {"type": "std::string", "name": "color"}, {"type": "int", "name": "weight"} ],
    "lang": "cpp" },
  { "type": "Person",
    "includes": [ "string" ],
    "fields": [ {"type": "std::string", "name": "gender"}, {"access": "protected", "type": "int", "name": "age"} ],
    "lang": "cpp" },
  { "type": "Circle",
    "fields": [ {"access": "public", "type": "float", "name": "diameter"} ],
    "lang": "cpp" },
  { "type": "Bike",
    "inheritance": [ "Product" ],
    "ports": [
      { "interface": "sc_signal_in_if", "type": "bool", "name": "cmd", "to": ["r->cmd"] },
      { "interface": "sc_signal_out_if", "type": "bool", "name": "res", "from": "w.res" }
    ],
    "channels": [
      { "interface": "sc_buffer", "type": "bool", "name": "r2w", "from": "r->res", "to": ["w.cmd"]} ],
    "fields": [
      { "access": "private", "type": "Rider*", "name": "r", "isClass": "true" },
      { "access": "private", "type": "Wheel", "name": "w", "isClass": "true" }
    ],
    "methods": [ { "access": "private", "name": "move", "in": "float speed" } ]
  },
  { "type": "Rider",
    "inheritance": [ "Person" ],
    "ports": [
      { "interface": "sc_signal_in_if", "type": "bool", "name": "cmd", "handler": "on_cmd", "next": [ { "interface": "sc_signal_out_if", "type": "bool", "name": "res" } ] },
      { "interface": "sc_signal_out_if", "type": "bool", "name": "res" }
    ]
  },
  { "type": "Wheel",
    "inheritance": [ "Circle" ],
    "ports": [
      { "interface": "sc_signal_in_if", "type": "bool", "name": "cmd", "handler": "on_cmd",
        "next": [ { "interface": "sc_signal_out_if", "type": "bool", "name": "res" } ] },
      { "interface": "sc_signal_out_if", "type": "bool", "name": "res" }
    ]
  },
  { "type": "Dummy", "lang": "cpp" }
];

const example1 = [
  {
    "type": "main",
    "channels": [
        { "interface": "sc_signal", "type": "int", "name": "x", "to": ["adder->x"], "init": "8" },
        { "interface": "sc_signal", "type": "int", "name": "y", "to": ["adder->y"], "init": "9" },
        { "interface": "sc_signal", "type": "float", "name": "z", "to": ["divider->z"], "init": "3.14" },
        { "interface": "sc_buffer", "type": "int", "name": "sum", "from": "adder->sum", "to": ["divider->sum"] },
        { "interface": "sc_buffer", "type": "float", "name": "div", "from": "divider->div" }
      ],
      "fields": [ { "type": "Adder*", "name": "adder", "isClass": "true"}, {"type": "Divider*", "name": "divider", "isClass": "true" } ]
  },
  { "type": "Adder",
    "ports": [
      { "interface": "sc_signal_in_if", "type": "int", "name": "x", "handler": "on_x"},
      { "interface": "sc_signal_in_if", "type": "int", "name": "y", "handler": "on_y",
        "next": [ { "interface": "sc_signal_out_if", "type": "int", "name": "sum" } ] },
      { "interface": "sc_signal_out_if", "type": "int", "name": "sum" }
    ],
    "methods": [ { "access": "private", "out": "int", "name": "sum2", "in": "int x, int y" } ]
  },
  { "type": "Divider",
    "ports": [
      { "interface": "sc_signal_in_if", "type": "int", "name": "sum", "handler": "on_sum"},
      { "interface": "sc_signal_in_if", "type": "float", "name": "z", "handler": "on_z",
        "next": [ { "interface": "sc_signal_out_if", "type": "float", "name": "div" } ] },
      { "interface": "sc_signal_out_if", "type": "float", "name": "div" }
    ],
    "methods": [ { "access": "private", "out": "float", "name": "div2", "in": "int sum, float z" } ]
  }
];

const example2 = [
  {
    "type": "main",
    "channels": [
      { "interface": "sc_signal", "type": "bool", "name": "x", "to": ["c1a.pi_x", "c1b.pi_x"], "init": "true" },
      { "interface": "sc_buffer", "type": "bool", "name": "ya", "from": "c1a.po_y" },
      { "interface": "sc_buffer", "type": "bool", "name": "yb", "from": "c1b.po_y" }
     ],
      "fields": [
        { "type": "Class1", "name": "c1a", "isClass": "true" },
        { "type": "Class1", "name": "c1b", "isClass": "true" }]
  },
  { "type": "Class1",
    "ports": [
      { "interface": "sc_signal_in_if", "type": "bool", "name": "pi_x", "handler": "on_x",
        "next": [ { "interface": "sc_signal_out_if", "type": "bool", "name": "po_y" } ] },
      { "interface": "sc_signal_out_if", "type": "bool", "name": "po_y" }
    ]
  }
];

const example3 = [
  {
    "type": "main",
    "channels": [
      { "interface": "sc_signal", "type": "bool", "name": "x", "to": ["l1.x"], "init": "true" },
      { "interface": "sc_buffer", "type": "bool", "name": "y", "from": "l1.y" }
     ],
      "fields": [ { "type": "Level1", "name": "l1", "isClass": "true" } ] },
  { "type": "Level1",
    "ports": [
      { "interface": "sc_signal_in_if", "type": "bool", "name": "x", "to": ["l2.x"] },
      { "interface": "sc_signal_out_if", "type": "bool", "name": "y", "from": "l2.y" }
    ],
    "fields": [ { "type": "Level2", "name": "l2", "isClass": "true" } ] },
  { "type": "Level2",
    "ports": [
      { "interface": "sc_signal_in_if", "type": "bool", "name": "x", "to": ["l3.x"] },
      { "interface": "sc_signal_out_if", "type": "bool", "name": "y", "from": "l3.y" }
    ],
    "fields": [ { "type": "Level3", "name": "l3", "isClass": "true" } ] },
  { "type": "Level3",
    "ports": [
      { "interface": "sc_signal_in_if", "type": "bool", "name": "x", "to": ["l4.x"] },
      { "interface": "sc_signal_out_if", "type": "bool", "name": "y", "from": "l4.y" }
    ],
    "fields": [ { "type": "Level4", "name": "l4", "isClass": "true" } ] },
  { "type": "Level4",
    "ports": [
      { "interface": "sc_signal_in_if", "type": "bool", "name": "x", "to": ["l5.x"] },
      { "interface": "sc_signal_out_if", "type": "bool", "name": "y", "from": "l5.y" }
    ],
    "fields": [ { "type": "Level5", "name": "l5", "isClass": "true" } ] },
  { "type": "Level5",
    "ports": [
      { "interface": "sc_signal_in_if", "type": "bool", "name": "x", "handler": "on_x",
        "next": [ { "interface": "sc_signal_out_if", "type": "bool", "name": "y" } ] },
      { "interface": "sc_signal_out_if", "type": "bool", "name": "y" }
    ]
  }
];

const example4 = [
  {
    "type": "main",
    "lang": "cpp",
    "fields": [
      { "type": "Class1", "name": "c1a", "isClass": "true" },
      { "type": "Class1", "name": "c1b", "isClass": "true" },
      { "type": "Class2*", "name": "pc2", "isClass": "true" }
    ],
    "methods": [ { "name": "execute" } ]
  },
  { "type": "Parent1"},
  { "type": "Parent2" },
  { "type": "Parent3", "inheritance": [ "Parent2" ] },
  { "type": "Class1",
    "includes": [ "vector" ],
    "inheritance": [ "Parent1" ],
    "fields": [
      { "type": "SubClass1", "name": "sc1", "isClass": "true" }
    ],
    "methods": [ { "access" : "private", "name": "function1" } ]
  },
  { "type": "SubClass1",
    "fields": [
      { "type": "SubSubClass1", "name": "ssc1", "isClass": "true" }
    ]
  },
  { "type": "SubSubClass1",
    "inheritance": [ "Parent3" ]
  },
  { "type": "Class2"
  }
];

const example5 = [
  { "type": "main", "lang": "cpp", "fields": [ { "type": "Factory", "name": "fac", "isClass": "true" } ] },
  { "type": "Food", "methods": [ { "name": "eat", "out": "virtual void"} ] },
  { "type": "Drink", "methods": [ { "name": "drink", "out": "virtual void"} ] },
  { "type": "Rice", "inheritance": [ "Food" ], "methods": [ { "name": "eat"} ] },
  { "type": "Wheat", "inheritance": [ "Food" ], "methods": [ { "name": "eat"} ] },
  { "type": "Juice", "inheritance": [ "Drink" ], "methods": [ { "name": "drink"} ] },
  { "type": "Water", "inheritance": [ "Drink" ], "methods": [ { "name": "drink"} ] },
  {
    "type": "Factory",
    "includes": [ "memory", "string", "food.h", "drink.h" ],
    "dependency": ["Rice", "Wheat", "Juice", "Water"],
    "methods": [
      { "name": "eat", "in": "const std::string& name", "out": "std::shared_ptr<Food>" },
      { "name": "drink", "in": "const std::string& name", "out": "std::shared_ptr<Drink>" }
    ]
  }
];

const examples = [example0, example1, example2, example3, example4, example5];

module.exports = {
  examples
};