const hdb = require('handlebars');

hdb.registerHelper('eq', function (a, b, options) { if (a === b) { return options.fn(this); } return options.inverse(this); });
hdb.registerHelper('isSystemC', function (a, options) { if (!a || a.toLowerCase === 'systemc') { return options.fn(this); } return options.inverse(this); });
hdb.registerHelper('eqNoStar', function (a, b, options) { a = a.split('*')[0].trim(); b = b.split('*')[0].trim(); if (a === b) { return options.fn(this); } return options.inverse(this); });
hdb.registerHelper('endsWith', function (a, b, options) { if (a.endsWith(b)) { return options.fn(this); } return options.inverse(this); });
hdb.registerHelper('isPublic', function (a, options) { if (!a || a === "public") { return options.fn(this); } return options.inverse(this); });
hdb.registerHelper('isPointer', function (a, options) { if (a.endsWith('*')) { return options.fn(this); } return options.inverse(this); });
hdb.registerHelper('needDstr', function (fields, options) { if (!fields) { return options.inverse(this); }; for (let i = 0; i < fields.length; ++i) { if (fields[i].isClass === 'true' && fields[i].type.endsWith('*')) { return options.fn(this); } }; return options.inverse(this); });
hdb.registerHelper('decField', function (type, name, size, isClass) {
  if (!isClass || isClass === 'false') { return type + ' ' + name + (size > 1 ? '[' + size + ']' : '') + ';'; }
  if (type.endsWith('*')) { return type + ' ' + name + ' = new ' + type.split('*')[0].trim() + '();'; }
  return type + ' ' + name + (size > 1 ? '[' + size + ']' : '') + ';'; });
hdb.registerPartial('removeStar', s => s.split('*')[0].trim());
hdb.registerPartial('removeStarLower', s => s.split('*')[0].trim().toLowerCase());
hdb.registerPartial('lower', s => s.toLowerCase());
hdb.registerPartial('upper', s => s.toUpperCase());
hdb.registerPartial('getname', s => s.split('.').pop().split('->').pop());
hdb.registerHelper('genName', function(parent, current) {
  if (current.includes('.')) { return current.split('.').join('_'); }
  if (current.includes('->')) { return current.split('->').join('_'); }
  if (parent === '') { return current; }
  return parent + '_' + current; });
hdb.registerPartial('array', size => size > 1 ? '[' + size + ']' : '');
hdb.registerPartial('component_partial', `{{#eq name ""}}{{else}}component {{> removeStar type}}::{{{name}}} { {{~/eq}}
{{#each current.channels}}  () "{{#genName ../name name}}{{/genName}}"
{{/each}}
{{#each ports}}  () "{{#genName ../name name}}{{/genName}}"
{{/each}}
{{#each ports}}{{#each next}}  {{#genName ../../name ../name}}{{/genName}}..>{{#genName ../../name name}}{{/genName}}
{{/each}}{{/each}}
{{#each fields}}{{#eq isClass "true"}}
{{#each ../full}}
{{#eqNoStar type ../type }}
{{> component_partial current=this type=../type name=../name full=../../full }}
{{/eqNoStar}}{{/each}}
{{/eq}}{{/each}}
{{#eq name ""}}{{else~}} }{{/eq}}
{{#each current.channels}}
{{#if from}}  {{#genName ../name from}}{{/genName}}-->{{#genName ../name name}}{{/genName}}{{/if}}
{{#if to}}{{#each to}}  {{#genName ../../name ../name}}{{/genName}}-->{{#genName ../../name this }}{{/genName}}
{{/each}}{{/if}}
{{/each}}
{{#each ports}}
{{#if from}}  {{#genName ../name from}}{{/genName}}-->{{#genName ../name name}}{{/genName}}{{/if}}
{{#if to}}{{#each to}}  {{#genName ../../name ../name}}{{/genName}}-->{{#genName ../../name this}}{{/genName}}
{{/each}}{{/if}}
{{/each}}`);
const classDiagramUml = hdb.compile(`' Learn with Examples, https://twitter.com/LearnWithEx

@startuml
{{#each this}}
class {{type}} {
{{#each channels}}
  {field} +{{{interface}}}<{{{type}}}> {{{name}}}{{> array size}};
{{/each}}
{{#each ports}}
  {field} +sc_port<{{{interface}}}<{{{type}}}>> {{{name}}}{{> array size}};
{{/each}}
{{#each fields}}{{#isPublic access}}
  {field} +{{{type}}} {{{name}}}{{> array size}};{{/isPublic}}
{{/each}}
{{#each methods}}{{#isPublic access}}
  {method} +{{#if out}}{{{out}}}{{else}}void{{/if}} {{{name}}}({{{in}}});{{/isPublic}}
{{/each}}
{{#each fields}}{{#eq access "protected"}}
  {field} #{{{type}}} {{{name}}}{{> array size}};{{/eq}}
{{/each}}
{{#each methods}}{{#eq access "protected"}}
  {method} #{{#if out}}{{{out}}}{{else}}void{{/if}} {{{name}}}({{{in}}});{{/eq}}
{{/each}}
{{#each fields}}{{#eq access "private"}}
  {field} -{{{type}}} {{{name}}}{{> array size}};{{/eq}}
{{/each}}
{{#each methods}}{{#eq access "private"}}
  {method} -{{#if out}}{{{out}}}{{else}}void{{/if}} {{{name}}}({{{in}}});{{/eq}}
{{/each}}
}
{{/each}}

{{#each this}}
{{#each inheritance}}
{{this}} <|-- {{../type}}
{{/each}}
{{#each fields}}{{#eq isClass "true"}}{{#isPointer type}}{{{../type}}} o-- {{> removeStar type}}{{else}}{{{../type}}} *-- {{{type}}}{{/isPointer}}{{/eq}}
{{/each}}
{{#each dependency}}
{{this}} <.. {{../type}}
{{/each}}
{{#each association}}
{{this}} -- {{../type}}
{{/each}}
{{/each}}
@enduml`);

const classDefinitionH = hdb.compile(`// Learn with Examples, https://twitter.com/LearnWithEx
#ifndef {{{type}}}_H
#define {{{type}}}_H
{{#each fields}}{{#eq isClass "true"}}#include "{{> removeStarLower type}}.h"{{/eq}}
{{/each}}
{{#each includes}}#include {{#endsWith this ".h"}}"{{{this}}}"{{else}}<{{{this}}}>{{/endsWith}}
{{/each}}
{{#each inheritance}}#include "{{> lower this}}.h"
{{/each}}

{{#isSystemC lang}}#include <systemc>
{{#if channels}}#include "__common.h"{{/if}}
using namespace sc_core;{{/isSystemC}}

class {{{type}}}{{#isSystemC lang}} : public sc_module{{#each inheritance}}, {{{this}}}{{/each}}{{else}}{{#if inheritance}} : public {{#each inheritance}}{{{this}}}{{#if @last}}{{else}}, {{/if}}{{/each}}{{/if}}{{/isSystemC}} {
public:
{{#isSystemC lang}}  SC_HAS_PROCESS({{{type}}});
  {{{type}}}(sc_module_name name);
{{#each channels}}  {{{interface}}}<{{{type}}}> {{{name}}}{{> array size}};
{{/each}}
{{#each ports}}  sc_port<{{{interface}}}<{{{type}}}> > {{{name}}}{{> array size}};
{{/each}}{{/isSystemC}}
{{#each fields}}{{#isPublic access}}{{#decField type name size isClass}}{{/decField}}{{/isPublic}}
{{/each}}
{{#each methods}}{{#if access}}{{#eq access "public"}}  {{#if out}}{{{out}}}{{else}}void{{/if}} {{{name}}}({{{in}}});{{/eq}}{{else}}  {{#if out}}{{{out}}}{{else}}void{{/if}} {{{name}}}({{{in}}});{{/if}}
{{/each}}
{{#needDstr fields}}  ~{{{type}}}() {
{{#each fields}}{{#isPointer type}}{{#eq isClass "true"}}   delete {{{name}}};{{/eq}}{{/isPointer}}
{{/each}}
  }{{/needDstr}}
protected:
{{#each fields}}{{#eq access "protected"}}  {{#decField type name size isClass}}{{/decField}}{{/eq}}
{{/each}}
{{#each methods}}{{#eq access "protected"}}  {{#if out}}{{{out}}}{{else}}void{{/if}} {{{name}}}({{{in}}});{{/eq}}
{{/each}}
private:
{{#each fields}}{{#eq access "private"}}  {{#decField type name size isClass}}{{/decField}}{{/eq}}
{{/each}}
{{#each methods}}{{#eq access "private"}}  {{#if out}}{{{out}}}{{else}}void{{/if}} {{{name}}}({{{in}}});{{/eq}}
{{/each}}
{{#isSystemC lang}}{{#each ports}}{{#if handler}}  void {{{handler}}}();{{/if}}
{{/each}}{{/isSystemC}}
};

{{#isSystemC lang}}{{{type}}}::{{{type}}}(sc_module_name name = sc_gen_unique_name("{{> lower type}}")) : sc_module(name) {
{{#each ports}}{{#if handler}}  SC_THREAD({{{handler}}});
  sensitive << {{> getname name}};
  dont_initialize();{{/if}}
{{/each}}
{{#each channels}}
{{#if to}}{{#each to}}  {{{this}}}({{{../name}}});
{{/each}}{{/if}}
{{#if from}}  {{{from}}}({{{name}}});{{/if}}
{{/each}}
{{#each ports}}
{{#if to}}{{#each to}}  {{{this}}}({{{../name}}});
{{/each}}{{/if}}
{{#if from}}  {{{from}}}({{{name}}});{{/if}}
{{/each}}

{{#each channels}}  sc_trace(file, {{{name}}}, "{{{name}}}");
{{/each}}
}{{/isSystemC}}

{{#isSystemC lang}}{{#each ports}}{{#if handler}}void {{{../type}}}::{{{handler}}}() {
{{#each next}}  {{{type}}} _{{> getname name}};
{{/each}}
  while (true) {
    std::cout << this->name() << " triggered at " << sc_time_stamp().to_seconds() << "s" << std::endl;
    {{#each next}}{{> getname name}}{{#if isPort}}{{#eq isPort "true"}}->{{else}}.{{/eq}}{{else}}->{{/if}}write(_{{> getname name}});{{/each}}
    wait();
  }
}{{/if}}
{{/each}}{{/isSystemC}}
#endif // {{{type}}}_H`);

const componentDiagramUml = hdb.compile(`' Learn with Examples, https://twitter.com/LearnWithEx

@startuml
skinparam BackgroundColor transparent
skinparam componentStyle rectangle
{{#each this}}{{#eq type "main"}}
{{> component_partial current=this name="" type="" full=../this }}
{{/eq}}{{/each}}
@enduml`);

const mainCpp = hdb.compile(`// Learn with Examples, https://twitter.com/LearnWithEx

{{#each fields}}{{#eq isClass "true"}}#include "{{> removeStarLower type}}.h"{{/eq}}
{{/each}}
{{#isSystemC lang}}
#include "__common.h"
#include <systemc>
using namespace sc_core;
{{#each methods}}{{#if out}}{{{out}}}{{else}}void{{/if}} {{{name}}}({{{in}}});
{{/each}}
sc_trace_file* file = sc_create_vcd_trace_file("trace");
int sc_main(int argc, char* argv[]) {
{{#each channels}}  {{{interface}}}<{{{type}}}> {{{name}}}{{> array size}};
{{/each}}
{{#each ports}}  sc_port<{{{interface}}}<{{{type}}}> > {{{name}}}{{> array size}};
{{/each}}
{{#each fields}}  {{#decField type name size isClass}}{{/decField}}
{{/each}}
{{#each channels}}
{{#if to}}{{#each to}}  {{{this}}}({{{../name}}});
{{/each}}{{/if}}
{{#if from}}  {{{from}}}({{{name}}});{{/if}}
{{/each}}
{{#each ports}}
{{#if to}}{{#each to}}  {{{this}}}({{{../name}}});
{{/each}}{{/if}}
{{#if from}}  {{{from}}}({{{name}}});{{/if}}
{{/each}}
{{#each channels}}  sc_trace(file, {{{name}}}, "{{{name}}}");
{{/each}}
{{#each channels}}{{#if init}}
  {{{type}}} _{{{name}}} = {{{init}}};
  {{{name}}}.write(_{{{name}}});
{{/if}}{{/each}}
  sc_start(1, SC_SEC);
  sc_close_vcd_trace_file(file);
{{else}}{{#each methods}}{{#if out}}{{{out}}}{{else}}void{{/if}} {{{name}}}({{{in}}});
{{/each}}
int main() {
{{#each fields}}  {{#decField type name size isClass}}{{/decField}}
{{/each}}
{{/isSystemC}}
{{#needDstr fields}}  
{{#each fields}}{{#isPointer type}}{{#eq isClass "true"}}  delete {{{name}}};{{/eq}}{{/isPointer}}
{{/each}}
{{/needDstr}}
  return 0;
}`);

const Makefile = `# Learn with Examples, https://twitter.com/LearnWithEx

SRC = $(wildcard *.cpp)
all:
	g++ -I. -g -O0 -I/usr/local/systemc-2.3.3/include -L/usr/local/systemc-2.3.3/lib-linux64 -Wl,-rpath=/usr/local/systemc-2.3.3/lib-linux64 $(SRC) -o $(basename $(SRC)) -lsystemc
clean:
	rm -f $(basename $(SRC))`;

const MakefileCpp = `# Learn with Examples, https://twitter.com/LearnWithEx

SRC = $(wildcard *.cpp)
all:
	g++ -I. -g -O0 $(SRC) -o $(basename $(SRC))
clean:
	rm -f $(basename $(SRC))
`;
  
  
const __common = `#ifndef __COMMON_H
#define __COMMON_H
#include <systemc>
extern sc_core::sc_trace_file* file;
#endif`;

const json2code = json => {
  const code = [];
  const main = json.filter(oneclass => oneclass.type === 'main')[0];
  if (main && main.lang === 'cpp') { json.forEach(oneclass => oneclass.lang = 'cpp'); } else { code.push( {name: "__common.h", content: __common} ); }
  json.filter(oneclass => oneclass.type !== 'main').forEach( oneclass => code.push( {name: oneclass.type.toLowerCase() + '.h', content: classDefinitionH(oneclass).replace(/^\s*[\r\n]/gm, '')} ));
  main && code.push( {name: 'main.cpp', content: mainCpp(main).replace(/^\s*[\r\n]/gm, '')} );
  if (main && main.lang === 'cpp') { code.push( {name: "Makefile", content: MakefileCpp} ); } else { code.push( {name: "Makefile", content: Makefile} ); }
  return code;
};

const json2uml = json => [ {name: 'class_diagram.wsd', content: classDiagramUml(json).replace(/^\s*[\r\n]/gm, '')}, {name: 'component_diagram.wsd', content: componentDiagramUml(json).replace(/^\s*[\r\n]/gm, '')} ];

module.exports = {
  json2code, json2uml
};
