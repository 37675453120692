import React, { useState } from 'react';
import Highlight from 'react-highlight.js';
import Scrollbar from './Scrollbar';
import './Code.css';

export default ({ example }) => {
  const [size, setSize] = useState(100);
  const [position, setPosition] = useState(40);
  return (
    <React.Fragment>
      <i className="plus square outline icon lefticons" onClick={() => setSize(size + 10)}></i>
      <i className="minus square outline icon lefticons" onClick={() => setSize(Math.max(0, size - 10))}></i>
      <div>
        <Scrollbar position={position} setPosition={setPosition} />
        <table className="code" style={{fontSize: size + '%'}}><tbody>
          {example.code_table.map((row, idx) => <tr key={idx}><td className="left" style={{width: position+'%'}}><pre>{row.right}</pre></td><td className="right" style={{width: (100-position)+'%'}}><pre style={{padding: '0px', fontSize: size + '%'}}><Highlight language="cpp">{row.left}</Highlight></pre></td></tr>)}
        </tbody></table>
        <span className="center">Result:</span>
        <table className="code" style={{fontSize: size + '%'}}><tbody>
          {example.res_table.map((row, idx) => <tr key={idx}><td className="left" style={{width: position+'%'}}><pre>{row.right}</pre></td><td className="right" style={{width: (100-position)+'%'}}><pre>{row.left}</pre></td></tr>)}
        </tbody></table>
      </div>
    </React.Fragment>
  );
};