import React, { useState, useEffect } from 'react';
import Desp from './Desp';
import Icons from './Icons';
import Code from './Code';
import './Body.css';

export default ({ example }) => {
  const [urlValid, setUrlValid] = useState(false);
  useEffect(() => window.scrollTo(0, 0), []); // first time load, go to top
  useEffect(() => { fetch(example.youtube, {mode:'no-cors'}).then(() => setUrlValid(true)).catch(() => {})}, [example]);
  return (
    <div className="body">
      {(example.desp || example.html) && <Desp desp={example.desp} html={example.html} url={urlValid ? example.youtube : null} />}
      <Icons example={example} />
      {example.code && <Code example={example} />}
    </div>
  );
};
