import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Intro.css';

export default ({ list }) => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div className="body intro">
      {/* <h3>Learn SystemC with Examples</h3> */}
      <ol>
      <li><Link to="/codegen">SystemC/C++ Code and UML Diagram Generator</Link></li>
      {list.map((l, i) =><li key={i}><Link to={l.url}>{l.title}</Link></li>)}
      </ol>
      <hr />
      <div><h3>SystemC</h3></div>
      <ul>
      <li><strong>is a C++ class library.</strong></li>
      <li>provides a mechanism for managing complex systems involving large numbers of components. It is capable of modeling hardware and software together at multiple levels of abstraction. This capability is not available in traditional hardware description languages.</li>
      <li>allows a designer to simulate concurrent processes, each described using plain C++ syntax. SystemC processes can communicate in a simulated real-time environment, using signals of all the datatypes offered by C++, some additional ones offered by the SystemC library, as well as user defined.</li>
      <li>is a system-level modeling language, which mimics the hardware description languages VHDL and Verilog.</li>
      <li>is applied to system-level modeling, architectural exploration, performance modeling, software development, functional verification, and high-level synthesis.</li>
      <li>is often associated with electronic system-level (ESL) design, and with transaction-level modeling (TLM).</li>
      <li>is standardized in <a href="https://standards.ieee.org/standard/1666-2011.html" target="_blank" rel="noopener noreferrer">IEEE 1666-2011</a>.</li>
      </ul>
      {/* <h3>What can SystemC do</h3>
      <ul>
      <li>Algorithmic Modeling</li>
      <li>Architectural Modeling</li>
      <li>Virtual Software Development Platform</li>
      <li>Hardware Refinement</li>
      <li>Functional Verification</li>
      <li>Architectural Verification</li>
      </ul> */}
      {/* <h3>Who may find SystemC useful?</h3>
      <ul>
      <li><strong>Hardware Engineer:</strong> <p>SystemC library provides data types that are designed for modeling digital logic and fixed-point arithmetic. It has data types close to Verilog, e.g. sc_logic, sc_lv, as well as hardware data types for mathematical calculations e.g. sc_fixed and sc_int.</p>
      <p>SystemC has simulation processes suitable to model hardware behavior: SC_METHOD and SC_THREAD.</p>
      <p>SystemC uses event, sensitivity to emulate concurrently running hardwares. Different simulation processes communicate with each other using events/channels/ports, and dependeicies are modelled via static and/or dynamic sensitivity lists.</p></li>
      <li><strong>System verification Engineer:</strong> SystemC models real-life blocks as modules. By modeling and connecting the various modules of a system, it's possible to build a simulation platform to verify the end-to-end performance of a system.</li>
      <li><strong>System Architect:</strong> SystemC is useful throughout the lifecycle of system architecture design:
      in the early stage of a project, an abstract-level SystemC modeling of a system (often without accurate functionality and timing modeling), can be used for architecture exploration. Though not accurate, it give a rough idea how a system architecture design may behavor.
      <p>As project processes, more details can be added to the SystemC architecture model, e.g. inter-component interface, processing time, power consumption, memory usage. This allows a system architect to better partition the functionalities among compoments, select a suitable IP, analyze the timeline, dimention the chipset etc.</p>
      </li>
      </ul>

      <h3>Language Comparisons and Levels of Abstraction</h3>
      <img src="language_comp.png" width="725px"></img>
      <div><a href="https://www.esa.int/Enabling_Support/Space_Engineering_Technology/Microelectronics/System-Level_Modeling_in_SystemC">original link</a></div> */}
      <hr/>
      <h3>General Terminology</h3>
      <div>Below lists the most common terms used for SystemC.</div>
      <table>
      <tbody>
      <tr><td>Method</td><td>A C++ method, i.e. a member function of a class.</td></tr>
      <tr><td><Link to="/basic/module">Module</Link></td><td>A structural entity, which can contain processes, ports, channels, and other modules. Modules allow expressing structural hierarchy. Module is the principle structural building block of SystemC, used to repsent a component in real systems.</td></tr>
      <tr><td><Link to="/basic/simu_process">Process</Link></td><td>A special kind of member function of a sc_module class, registered to the SystemC simulation kernel and called only by the simulation Kernel.</td></tr>
      <tr><td>Interface</td><td>An interface provides a set of method declarations, but provides no method implementations and no data fields.</td></tr>
      <tr><td><Link to="/basic/signal_readwrite">Channel</Link></td><td>A channel implements one or more interfaces, and serves as a container for communication functionality.</td></tr>
      <tr><td><Link to="/basic/port">Port</Link></td><td>A port is an object through which a module can access a channel’s interface. But modules can also access a channel’s interface directly.</td></tr>
      <tr><td><Link to="/basic/event">Event</Link></td><td>A process can suspend on, or be sensitive to, one or more events. Events allow for resuming and activating processes.</td></tr>
      <tr><td><Link to="/basic/sensitivity">Sensitivity</Link></td><td>The sensitivity of a process defines when this process will be resumed or activated. A process can be sensitive to a set of events. Whenever one of the corresponding events is triggered, the process is resumed or activated.</td></tr>
      </tbody></table>
      <hr/>
      <h3>SystemC Components</h3>
      <div>Below shows an example of a typical SystemC simulation with different types of components. Typically:
        <ul>
        <li>processes within the same module communicate to each other via events or channels.</li>
        <li>processes within different modules communicate to each other via ports and channels</li>
      </ul></div>
      <img src="sc_components.png" width="500px" alt="systemc_components"></img>
      <div>In this example:
      <ul>
      <li>Module A is a top level module (component). It has two sub-modules (module b and module c), a channel (c1), and a simulation process (P). Module A also has one input port (p0) and two output ports (p4, p7).</li>
      <li>Module A receives input via port p0, and writes output via ports p4 and p7</li>
      <li>Module b has two simulation processes (X and Y), an event (e), an input port (p1), and two output ports (p2, p3)</li>
      <li>Within module b, processes X and Y are connected through event e.</li>
      <li>Module b connected to module A via ports p0 and p1.</li>
      <li>Module b also connects to process P of module A via port p3.</li>
      <li>Module c has two simulation processes (M and N), an input port (p5), and an output port (p6)</li>
      <li>Within module c, processes M and N are connected through channel c.</li>
      <li>Module b is connected to module c via channel c1 (ports p2 and p5 bound to c1).</li>
      <li>Module c connected to module A via ports p6 and p7.</li>
      <li>Overall, there are following signal chaines:
        <ul>
          <li>ext -> p0 -> p1 -> X -> e -> Y -> p2 -> c1 -> p5 -> N - c2 - M -> p6 -> p7 -> ext</li>
          <li>ext -> p0 -> p1 -> X -> p3 -> P -> p4 -> ext</li>
        </ul>
      </li>
      </ul></div>
      <hr/>
      <h3>SystemC Execution Stages</h3>
      <div>The execution of a SystemC application consists of elaboration followed by simulation. Refer to <a href="/basic/simu_stage">simulation stages</a> for details. A diagram is provided below.</div>
      <img src="sc_kernel.png" width="500px" alt="systemc_kernel"></img>
      <div>As shown,
      <ul>
        <li>sc_main() is the entrying point of an SystemC application. Before and after sc_start() is normal C++ code.</li>
        <li>Upon reaching sc_start(), SystemC simulation kernel takes over the scheduling of processes. It returns until a specified simulation time has elapsed, no more actions pending, or sc_stop() is called by any of the process.</li>
      </ul></div>
      <hr/>
      <h3>References</h3>
      <ol>
        <li><a href="https://www.accellera.org/downloads/standards/systemc" target="_blank" rel="noopener noreferrer">Download SystemC</a></li>
        <li><a href="https://standards.ieee.org/standard/1666-2011.html" target="_blank" rel="noopener noreferrer">IEEE 1666-2011</a></li>
        <li><a href="https://github.com/systemc/systemc-2.3" target="_blank" rel="noopener noreferrer">SystemC 2.3 source code</a></li>
        <li><a href="https://github.com/systemc/systemc-2.3/blob/master/docs/FuncSpec20.pdf" target="_blank" rel="noopener noreferrer">SystemC 2.0 Functional Specification</a></li>
        <li><a href="https://www.edaplayground.com/" target="_blank" rel="noopener noreferrer">EDA playground</a></li>
        <li><a href="https://www.springer.com/gp/book/9780387699578" target="_blank" rel="noopener noreferrer">SystemC: From the Ground Up, Second Edition, 2010, by David C. Black et al.</a></li>
        <li><a href="https://www.youtube.com/channel/UC6voIK-YuToVpEw6SnGTpTg" target="_blank" rel="noopener noreferrer">Youtube Video Course: Learn SystemC with Examples</a></li>
        <li><a href="https://github.com/learnwithexamples/learnsystemc" target="_blank" rel="noopener noreferrer">Github repository: Learn SystemC with Examples</a></li>
      </ol>
    </div>
  );
};
