import React from 'react';
import "./Icons.css";

 export default ( {example} ) => {
  const toClipboard = () => navigator.clipboard.writeText(example.code);
  return (
    <React.Fragment>
      {example.eda !== null && <a href={example.eda} target="_blank" rel="noopener noreferrer"><img title="Run code" src="/eda.ico" className="righticons" id="run" alt="run"/></a>}
      {example.git !== null && <a href={example.git} target="_blank" rel="noopener noreferrer"><i className="github icon righticons"></i></a>}
      {example.docker !== null && <a href={example.docker} target="_blank" rel="noopener noreferrer"><i className="docker icon righticons"></i></a>}
      {example.youtube !== null && <a href={example.youtube} target="_blank" rel="noopener noreferrer"><i className="youtube icon righticons"></i></a>}
      {/* {example.bili !== null && <a href={example.bili} target="_blank" rel="noopener noreferrer"><i className="bili icon"></i></a>} */}
      {example.code && <a href={'data:text/plain;charset=UTF-8,'+encodeURIComponent(example.code)} download={example.file}><i className="download icon righticons"></i></a>}
      {example.code && <i className="copy outline icon righticons" onClick={toClipboard}></i>}
    </React.Fragment>
  );
};