import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

export default ({ list, width, showSidebar }) => {
  useEffect(() => {
    const coll = document.getElementsByClassName("dropdown-toggle");
    for (let i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function(e) {
        e.preventDefault();
        this.nextElementSibling.classList.toggle("show");
      });
    }
  }, []);

  return (
    <React.Fragment>
      <div id="sidebar" className={width > 768 && showSidebar ? 'active' : ''}>
          <div className="sidebar-header"><h4>Learn SystemC by examples</h4></div>
          <ul className="list-unstyled components">
            <li><Link to="/codegen">Code generator</Link></li>
            <li>
                <a href="#setup" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Environment Setup</a>
                <ul className="collapse list-unstyled show" id="setup">
                  {list.filter(l=>l.url.startsWith('/setup/')).map((l, i) =><li key={i}><Link to={l.url}>{l.title}</Link></li>)}
            </ul></li>
            <li>
              <a href="#basic" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Language Basics</a>
              <ul className="collapse list-unstyled show" id="basic">
                {list.filter(l=>l.url.startsWith('/basic/')).map((l, i) =><li key={i}><Link to={l.url}>{l.title}</Link></li>)}
          </ul></li>
          </ul>
          <ul className="list-unstyled CTAs">
            <li><a href="https://github.com/learnwithexamples/learnsystemc" className="article">Source Code</a></li>
            <li><a href="/" className="article">Home</a></li>
          </ul>
      </div>
    </React.Fragment>
  );  
};
